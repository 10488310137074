.typewriter {
  background: #fff;
  padding: 10px;
  /* border: 1px solid #ddd;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3); */
  max-width: 640px;
}

@keyframes letters {
  from {
    left: 0;
  }
  to {
    left: 50ch;
  }
}
@keyframes lines {
  from {
    top: 0;
  }
  to {
    top: 100%;
  }
}
@keyframes cursor-type {
  50% {
    border-color: #000;
  }
}
@keyframes cursor-wait {
  /* 0% {
    box-shadow: inset 2px 0 0 0 rgba(0, 0, 0, 0);
  }
  1% {
    box-shadow: inset 2px 0 0 0 black;
  }
  40% {
    box-shadow: inset 2px 0 0 0 black;
  }*/
}

.type {
  position: relative;
  font-family: Consolas, Monaco, monospace;
  width: 80ch;
  line-height: 1.2;
  word-break: break-word;
  overflow: hidden;
}

@supports (width: 1ch) {
  .type:before,
  .type:after {
    box-sizing: border-box;
    content: "";
    position: absolute;
    z-index: 1;
    display: block;
    height: 1.2em;
    width: 400px;
    background-color: #fff;
  }
  .type:before {
    border-left: 2px solid transparent;
    top: 100%;
    animation-name: letters, lines, cursor-type, cursor-wait;
    animation-fill-mode: both, both, both, backwards;
  }
  .type:after {
    content: "";
    height: 100%;
    top: 100%;
    left: 0;
    margin-top: 1.2em;
  }
}

.type:before {
  animation-duration: 1.4285714286s, 1.4285714286s, 0.0285714286s, 0.8s;
  animation-timing-function: steps(50, end), steps(1, end), step-end, ease-out;
  animation-iteration-count: 1, 1, 35, 2;
  animation-delay: 1.6s, 1.6s, 1.6s, 0s;
}
.type:after {
  animation: lines 1.4285714286s steps(1, end) 1;
  animation-fill-mode: both;
  animation-delay: 1.6s;
}

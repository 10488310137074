@media only screen and (min-device-width: 712px) and (max-device-width: 1138px) and (orientation: portrait) {
  #tool_bar_logo {
    margin-left: 33% !important;
    width: 200px !important;
  }
  #apartment_id {
    margin-left: 33% !important;
    font-size: 13px !important;
  }
}

.container {
  width: 90%;
  margin: auto;
}
* {
  box-sizing: border-box;
}
/*
        Titres et paragraphes
    */
.calliope-title-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.calliope-title-section {
  background: white;
  background-size: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-repeat: no-repeat;
  background-position: center;
  border-bottom: 1px solid #ccc;
}
.calliope-title-section .home-title {
  position: relative;
  color: #888;
  border: 1px solid #aaa;
  padding: 1em;
  border-radius: 0.1em;
  margin: 0.3em 0;
  font-weight: 500;
}
.calliope-title-section .home-title:before {
  content: "";
  position: absolute;
  border-width: 20px;
  border-style: solid;
  border-color: transparent #aaa transparent transparent;
  top: 40px;
  left: -40px;
}
.calliope-title-section .home-title:after {
  content: "";
  position: absolute;
  border-width: 20px;
  border-style: solid;
  border-color: transparent white transparent transparent;
  top: 40px;
  left: -39px;
}
/*
      Chat
     */
.chat-container {
  position: relative;
  font-size: 16px;
  width: 100%;
  /* border: 1px solid #ddd;
  border-radius: 5px;
  margin: 20px 0; */
}
.chat-module {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  position: relative;
  height: 75vh;
  overflow-y: scroll;
  margin: auto;
  font-family: "Open Sans";
  font-size: 16px;
  color: #222;
}
.thread {
  width: 100%;
}
.thread-item {
  display: flex;
  margin: 1em 0;
  width: 100%;
  min-height: 54px;
}
.thread-item.user-item .avatar-container {
  border-right: 2px solid hsl(30, 80%, 73%);
}
.thread-item.user-item .avatar-container:after,
.thread-item.user-item .avatar-container:before {
  display: none;
}
.avatar-container {
  position: relative;
  width: 64px;
  padding: 0 6px;
  border-right: 1px solid hsl(21, 93%, 58%);
}
.avatar-container:before {
  content: "";
  position: absolute;
  border-width: 10px;
  border-style: solid;
  border-color: transparent hsl(21, 93%, 58%) transparent transparent;
  top: 10px;
  right: -1px;
}
.avatar-container:after {
  content: "";
  position: absolute;
  border-width: 10px;
  border-style: solid;
  border-color: transparent white transparent transparent;
  top: 10px;
  right: -2px;
}
.response-container {
  width: 90%;
  padding: 5px 1em;
}
.scroll-overlay {
  pointer-events: none;
  position: absolute;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 50vh;
  background: linear-gradient(
    white 5%,
    hsla(0, 0%, 0%, 0) 60%,
    hsla(0, 0%, 0%, 0)
  );
  opacity: 1;
  transition: opacity 3s;
}
.scroll-overlay.overlay-hidden {
  opacity: 0;
}
.form-prompt {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
}
.user-prompt-indicator {
  width: 64px;
  height: 60px;
  padding-top: 0;
  padding-right: 10px;
  color: hsl(30, 80%, 73%);
  text-align: right;
  font-size: 42px;
}
.user-prompt {
  width: 90%;
  height: 60px;
  padding: 1em;
  border: 1px solid #ccc;
  border-radius: 3px;
  color: #666;
}
.user-prompt:focus {
  outline: none;
}
.user-mic-toggle:after {
  font-family: "FontAwesome";
  content: "\f130";
}
.response {
  min-height: 1em;
  margin: 0.5em 0;
}
.char {
  animation: charReveal 0.35s;
  display: inline-block;
}
@keyframes cursor {
  0% {
    background: hsl(21, 93%, 58%);
  }
  100% {
    background: #222;
  }
}
@keyframes charReveal {
  0% {
    color: white;
    transform: scale(2, 4);
    font-weight: bold;
  }
  25% {
    color: hsl(21, 93%, 58%);
  }
  50% {
    color: hsl(30, 80%, 73%);
  }
  75% {
    color: hsl(21, 93%, 58%);
  }
  100% {
    color: #222;
    transform: scale(1, 1);
  }
}
/*
     * Bot avatar
     */
.calliope {
  position: relative;
  width: 54px;
  height: 54px;
  will-change: transform;
  transform-origin: 50% 85%;
}
.calliope .head {
  position: absolute;
  z-index: 20;
  left: 14.85px;
  background: hsl(21, 93%, 58%);
  width: 24.3px;
  height: 24.3px;
  border-radius: 24.3px;
  border: 2.16px solid white;
  transform-origin: 50% 65%;
}
.calliope .head:after {
  position: relative;
  background: hsl(21, 93%, 58%);
  display: block;
  left: -1.701px;
  top: 6.075px;
  content: "";
  width: 24.354px;
  height: 9.72px;
  border-radius: 1.944px;
}
.calliope .head .eye-left,
.calliope .head .eye-right {
  background: white;
  position: absolute;
  z-index: 1;
  top: 7.29px;
  width: 4.32px;
  height: 4.32px;
  border-radius: 4.32px;
}
.calliope .head .eye-left {
  left: 3.645px;
}
.calliope .head .eye-right {
  left: 12.636px;
}
.calliope .torso {
  position: absolute;
  left: 16.2px;
  top: 21.06px;
  z-index: 1;
  background: hsl(21, 93%, 58%);
  width: 21.6px;
  height: 21.6px;
  border-radius: 5px;
  border: 2.16px solid white;
}
.calliope .torso .arm-left,
.calliope .torso .arm-right {
  background: hsl(21, 93%, 58%);
  position: absolute;
  z-index: 1;
  top: -1.08px;
  width: 9.72px;
  height: 21.6px;
  border: 2.16px solid white;
}
.calliope .torso .arm-left {
  left: -1.08px;
  border-radius: 21.6px 2.16px;
  transform-origin: 100% 0%;
  transform: rotate(20deg);
}
.calliope .torso .arm-right {
  left: 8.64px;
  border-radius: 2.16px 21.6px;
  transform-origin: 0% 0%;
  transform: rotate(-20deg);
}
.calliope .feet {
  position: absolute;
  top: 39.96px;
  left: 18.36px;
  background: hsl(21, 93%, 58%);
  width: 17.28px;
  height: 10.8px;
  border-radius: 10.8px 10.8px 0 0;
  border: 2.16px solid white;
}
.calliope.big {
  margin-top: 35px;
  width: 200px;
  height: 200px;
  will-change: transform;
  transform-origin: 50% 85%;
}
.calliope.big .head {
  position: absolute;
  z-index: 20;
  left: 55px;
  background: hsl(21, 93%, 58%);
  width: 90px;
  height: 90px;
  border-radius: 90px;
  border: 8px solid white;
  transform-origin: 50% 65%;
}
.calliope.big .head:after {
  position: relative;
  background: hsl(21, 93%, 58%);
  display: block;
  left: -6.3px;
  top: 22.5px;
  content: "";
  width: 90.2px;
  height: 36px;
  border-radius: 7.2px;
}
.calliope.big .head .eye-left,
.calliope.big .head .eye-right {
  background: white;
  position: absolute;
  z-index: 1;
  top: 27px;
  width: 16px;
  height: 16px;
  border-radius: 16px;
}
.calliope.big .head .eye-left {
  left: 13.5px;
}
.calliope.big .head .eye-right {
  left: 46.8px;
}
.calliope.big .torso {
  position: absolute;
  left: 60px;
  top: 78px;
  z-index: 1;
  background: hsl(89, 50%, 65%);
  width: 80px;
  height: 80px;
  border-radius: 500px 500px 5px 5px;
  border: 8px solid white;
}
.calliope.big .torso .arm-left,
.calliope.big .torso .arm-right {
  background: hsl(30, 80%, 73%);
  position: absolute;
  z-index: 1;
  top: -4px;
  width: 36px;
  height: 80px;
  border: 8px solid white;
}
.calliope.big .torso .arm-left {
  left: -4px;
  border-radius: 80px 8px;
  transform-origin: 100% 0%;
  transform: rotate(20deg);
}
.calliope.big .torso .arm-right {
  left: 32px;
  border-radius: 8px 80px;
  transform-origin: 0% 0%;
  transform: rotate(-20deg);
}
.calliope.big .feet {
  position: absolute;
  top: 148px;
  left: 68px;
  background: hsl(30, 80%, 73%);
  width: 64px;
  height: 40px;
  border-radius: 40px 40px 0 0;
  border: 8px solid white;
}
.calliope.inactive {
  opacity: 0.4;
}
.calliope.idle {
  animation: idleCalliope 38s ease-in-out infinite;
}
.calliope.idle .head {
  animation: idleHead 24s infinite;
}
.calliope.idle .head .eye-left,
.calliope.idle .head .eye-right {
  animation: idleEyes 18s ease-in-out infinite;
}
.calliope.idle .torso {
  animation: idleTorso 8s ease-in-out infinite;
}
.calliope.idle .torso .arm-left {
  animation: idleLeftArm 7s ease-in-out infinite;
}
.calliope.idle .torso .arm-right {
  animation: idleRightArm 7s ease-in-out infinite;
}
.calliope.big.idle .arm-left {
  z-index: 10;
  animation: idleAndSaluteLeftArm 20s ease-in-out infinite;
}
.calliope.appearing {
  animation: fallIn 0.25s ease-out;
}
.calliope.appearing .head {
  animation: fallInHead 0.6s ease-out 0.3s, colorFlash 1s;
}
.calliope.appearing .head:after {
  animation: colorFlash 1s;
}
.calliope.appearing .torso {
  animation: fallInTorso 0.6s ease-out 0.25s, colorFlash 1s;
}
.calliope.appearing .torso .arm-left {
  animation: fallInLeftArm 0.5s ease-out 0.15s, colorFlash 1s;
}
.calliope.appearing .torso .arm-right {
  animation: fallInRightArm 0.7s ease-out 0.1s, colorFlash 1s;
}
.calliope.appearing .feet {
  animation: colorFlash 1s;
}
@keyframes colorFlash {
  0%,
  20% {
    background: hsl(21, 100%, 50%);
  }
  100% {
    background: hsl(21, 93%, 58%);
  }
}
@keyframes fallIn {
  0% {
    opacity: 0;
    top: -27px;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}
@keyframes fallInHead {
  0%,
  100% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, 32%);
  }
}
@keyframes fallInTorso {
  0%,
  100% {
    transform: translate(0, 0) scale(1, 1);
  }
  50% {
    transform: translate(0, 15%) scale(1.1, 0.9);
  }
}
@keyframes fallInLeftArm {
  0%,
  100% {
    transform: rotate(20deg);
  }
  50% {
    transform: rotate(40deg);
  }
}
@keyframes fallInRightArm {
  0%,
  100% {
    transform: rotate(-20deg);
  }
  50% {
    transform: rotate(-40deg);
  }
}
@keyframes idleCalliope {
  0%,
  35%,
  50%,
  65%,
  100% {
    transform: translate(0%, 0);
  }
  40%,
  45% {
    transform: translate(5%, 0);
  }
  55%,
  60% {
    transform: translate(-5%, 0);
  }
}
@keyframes idleCalliopeForward {
  0%,
  40%,
  60%,
  100% {
    transform: scale(1, 1) translate(0, 0);
  }
  45%,
  55% {
    transform: scale(1.2, 1.2) translate(0, 10%);
  }
}
@keyframes idleHead {
  0%,
  45%,
  60%,
  100% {
    transform: rotate(0deg);
  }
  50%,
  55% {
    transform: rotate(25deg);
  }
}
@keyframes idleEyes {
  0%,
  25%,
  50%,
  54%,
  57%,
  70%,
  85%,
  100% {
    transform: translate(0%, 0%) scale(1, 1);
  }
  30%,
  40% {
    transform: translate(40%, 0%) scale(1, 1);
  }
  55%,
  56% {
    transform: translate(0%, 0%) scale(1.2, 0.1);
  }
  75%,
  80% {
    transform: translate(0%, 45%) scale(1, 1);
  }
}
@keyframes idleTorso {
  0%,
  100% {
    transform: scale(1, 1);
  }
  30%,
  60% {
    transform: scale(0.9, 1.1);
  }
}
@keyframes idleLeftArm {
  0%,
  25%,
  80%,
  100% {
    transform: rotate(20deg) scale(1);
  }
  50%,
  55% {
    transform: rotate(25deg) scale(1.1);
  }
}
@keyframes idleAndSaluteLeftArm {
  0%,
  5%,
  68%,
  80%,
  100% {
    transform: rotate(20deg) scale(1) translate(0, 0);
  }
  30%,
  35% {
    transform: rotate(25deg) scale(1.1) translate(0, 0);
  }
  70%,
  74%,
  78% {
    transform: rotate(120deg) scale(1) translate(100%, 30%);
  }
  72%,
  76% {
    transform: rotate(105deg) scale(1) translate(100%, 30%);
  }
}
@keyframes idleRightArm {
  0%,
  25%,
  80%,
  100% {
    transform: rotate(-20deg) scale(1);
  }
  50%,
  55% {
    transform: rotate(-25deg) scale(1.1);
  }
}
/*
     * Scrollbars
     */
.chat-module::-webkit-scrollbar {
  width: 8px;
}
.chat-module::-webkit-scrollbar-track {
  background: transparent;
}
/*.chat-module::-webkit-scrollbar-thumb {
  background: hsl(21, 93%, 66%);
  border-radius: 5px;
}*/

@media only screen and (min-device-width: 390px) and (max-device-width: 844px) and (orientation: portrait) {
  #tool_bar_logo {
    margin-left: 17% !important;
    margin-bottom: 0px !important;
    width: 45px !important;
    height: 45px !important;
  }
  #apartment_id {
    margin-left: 30% !important;
  }
  #custom_title_id {
    margin-left: 0px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
}

@media only screen and (min-device-width: 280px) and (max-device-width: 653px) and (orientation: portrait) {
  #tool_bar_logo {
    margin-left: 26% !important;
    width: 100px !important;
  }
  #apartment_id {
    margin-left: 20% !important;
    font-size: 8px !important;
  }
}

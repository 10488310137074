@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: portrait) {
  #tool_bar_logo {
    margin-left: 16% !important;
  }
  #apartment_id {
    margin-left: 27% !important;
  }
  #custom_title_id {
    margin-left: 0px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
}
